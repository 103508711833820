import React from "react";
import { Button, Typography, Box, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CheckoutSuccess = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleGoBack = () => {
    navigate("/"); // Redirige l'utilisateur vers la page d'accueil ou la boutique
  };

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: theme.palette.background.alt,
        height: "100%",
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom color="success.main">
        Paiement Réussi !
      </Typography>
      <Typography variant="body1" textAlign="center" gutterBottom>
        Merci pour votre achat. Votre commande a été traitée avec succès. Vous
        recevrez un e-mail de confirmation sous peu.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleGoBack}
        sx={styles.button}
      >
        Retourner à la boutique
      </Button>
    </Box>
  );
};

const styles = {
  button: {
    marginTop: "2rem",
  },
};

export default CheckoutSuccess;
