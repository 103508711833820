import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "scenes/homepage";
import { useMemo } from "react";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { themeSettings } from "./theme";
import CheckoutCancel from "scenes/stripe/checkoutCancel";
import CheckoutSuccess from "scenes/stripe/checkoutSuccess";

function App() {
  const theme = useMemo(() => createTheme(themeSettings()), []);

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route
              path="/stripe-checkout-cancel"
              element={<CheckoutCancel />}
            />
            <Route
              path="/stripe-checkout-success"
              element={<CheckoutSuccess />}
            />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
