import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  ref: null,
  page: 1,
  datas: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setRef: (state, action) => {
      state.ref = action.payload.ref;
      state.datas["ref"] = action.payload.ref;
    },
    setPage: (state, action) => {
      state.page = action.payload.page;
    },
    setDatas: (state, action) => {
      state.datas = action.payload.datas;
    },
  },
});

export const { setRef, setDatas, setPage } = authSlice.actions;
export default authSlice.reducer;
