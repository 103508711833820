import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { iso4217 } from "constants/form.constants";
import { useSelector } from "react-redux";
import apiService from "services/apiService";
import Flexbetween from "./FlexBetween";

const StripeCard = ({ result }) => {
  const theme = useTheme();
  const datas = useSelector((state) => state.datas);

  const handleCheckout = async (priceId) => {
    try {
      const { data, error } = await apiService.postData(
        "/stripe/create-checkout-session",
        { formData: datas, price_id: priceId }
      );

      if (data.url) {
        window.location.href = data.url; // Redirection vers l'URL de la session Checkout
      }
    } catch (error) {
      console.error(
        "Erreur lors de la création de la session Checkout:",
        error
      );
    }
  };

  const getCurrencySymbol = (currency) => {
    const jsonCurrency = iso4217[currency.toUpperCase()];
    if (jsonCurrency) {
      return jsonCurrency.symbol;
    }

    return currency;
  };

  return (
    <Grid item key={result.id}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          maxWidth: "300px",
          padding: "16px",
          borderRadius: "8px",
          backgroundColor: theme.palette.background.default,
          boxShadow:
            "0px 4px 6px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.06)",
          transition: "transform 0.2s",
          "&:hover": {
            transform: "scale(1.05)",
          },
          cursor: "pointer",
        }}
      >
        {result.image && (
          <img
            style={{ height: "60px" }}
            src={result.image}
            alt="stripe image"
          />
        )}
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.primary.extra,
            mt: "10px",
            mb: "8px",
            textAlign: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
          }}
        >
          {result.name}
        </Typography>

        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
            mb: "16px",
            textAlign: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "3",
            WebkitBoxOrient: "vertical",
          }}
        >
          {result.description}
        </Typography>

        <Typography
          variant="h3"
          sx={{
            color: theme.palette.neutral.dark,
            lineHeight: "1.4",
            marginBottom: "12px",
            textAlign: "center",
          }}
        >
          {result.price / 100} {getCurrencySymbol(result.currency)}
        </Typography>
        <Button
          onClick={() => handleCheckout(result.price_id)}
          sx={{ width: "100%" }}
          variant="outlined"
        >
          En savoir plus
        </Button>
        <Flexbetween
          sx={{
            mt: "10px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "5px",
          }}
        >
          {result.marketing_features.map((feature) => {
            return (
              <Typography sx={{ fontWeight: 600 }}>{feature.name}</Typography>
            );
          })}
        </Flexbetween>
      </Box>
    </Grid>
  );
};

export default StripeCard;
