import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

export default function ARadioGroup({ name, choices, value, onChange }) {
  return (
    <RadioGroup
      aria-labelledby={`radio-buttons-group-${name}`}
      name={name}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      {choices.map((choice) => {
        return (
          <FormControlLabel
            key={`radio-buttons-group-form-${name}-${choice}`}
            componentsProps={{ typography: { variant: "h5" } }}
            value={choice}
            control={<Radio />}
            label={choice}
          />
        );
      })}
    </RadioGroup>
  );
}
