import {
  Autocomplete,
  Box,
  Button,
  Fab,
  TextField,
  Typography,
  Zoom,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Flexbetween from "components/FlexBetween";
import Navbar from "scenes/navbar";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDatas, setPage, setRef } from "state";
import {
  fieldLabels,
  formConstants,
  questionRender,
} from "constants/form.constants";
import AddressSearch from "components/AddressSearch";
import apiService from "services/apiService";
import NavigationBar from "components/NavigationBar";
import StripeProducts from "components/StripeProducts";
import { InfoOutlined } from "@mui/icons-material";
import InfoDialog from "components/InfoDialog";
import ARadioGroup from "components/ARadioGroup";
import EmptyDialog from "components/emptyDialog";

const Homepage = () => {
  const largeScreen = useMediaQuery("(min-width: 800px)");
  const dispatch = useDispatch();
  const ref = useSelector((state) => state.ref);
  const page = useSelector((state) => state.page);
  const datas = useSelector((state) => state.datas);
  const theme = useTheme();

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const { data, error } = await apiService.postData("/auth/register");
        dispatch(setRef({ ref: data.ref }));
      } catch (error) {
        console.error(error);
      }
    };

    if (!ref) {
      fetchToken();
    }
  }, []);

  const updateUserData = async (formData) => {
    try {
      const { data, error } = await apiService.postData(
        "/users/addOrUpdateUser",
        formData
      );
      if (error) {
        console.error(error);
      }
    } catch (error) {
      console.error(
        "Erreur lors de la création de la session Checkout:",
        error
      );
    }
  };

  const isStepEnable = (step) => {
    if (step > 1 && !isStepEnable(step - 1)) {
      return false;
    }
    switch (step) {
      case 1:
        return datas.firstName && datas.lastName && datas.email && datas.phone;
      case 2:
        return datas.dateCreation;
      case 3:
        return datas.firstTimeCreation == "Oui" || datas.previousCreation;
      case 4:
        return datas.userStatus;
      case 5:
        return datas.nomSociete;
      case 6:
        return datas.copyright;
      case 7:
        return (
          (datas.activity && datas.activity != "Autres") || datas.otherActivity
        );
      case 8:
        return (
          datas.activityChambreDesMetiers == "Non" ||
          (datas.activityChambreDesMetiers &&
            datas.activityChambreDesMetiersGere)
        );
      case 9:
        return datas.address;
      case 10:
        return true;
      case 11:
        return (
          isStepEnable(1) &&
          isStepEnable(2) &&
          isStepEnable(3) &&
          isStepEnable(4) &&
          isStepEnable(5) &&
          isStepEnable(6) &&
          isStepEnable(7) &&
          isStepEnable(8) &&
          isStepEnable(9)
        );
      default:
        return true;
    }
  };

  const formRender = () => {
    const updateState = (key, value) => {
      const newDatas = Object.assign({}, datas);
      if (value == null || value === undefined) {
        delete newDatas[key];
      } else {
        newDatas[key] = value;
      }
      if (key == "firstTimeCreation" && value == "Oui") {
        delete newDatas["previousCreation"];
      }
      dispatch(setDatas({ datas: newDatas }));
      updateUserData(newDatas);
    };

    switch (page) {
      case 1:
        return (
          <>
            <TextField
              value={datas?.lastName}
              onChange={(e) => updateState("lastName", e.target.value)}
              fullWidth
              label={fieldLabels["lastName"]}
              name="lastName"
            />
            <TextField
              value={datas?.firstName}
              onChange={(e) => updateState("firstName", e.target.value)}
              fullWidth
              label={fieldLabels["firstName"]}
              name="firstName"
            />
            <TextField
              defaultValue={datas?.email}
              onChange={(e) => updateState("email", e.target.value)}
              fullWidth
              label={fieldLabels["email"]}
              name="email"
            />
            <TextField
              value={datas?.phone}
              onChange={(e) => updateState("phone", e.target.value)}
              fullWidth
              label={fieldLabels["phone"]}
              name="phone"
            />
          </>
        );
      case 2:
        return (
          <ARadioGroup
            name="dateCreation"
            choices={[
              "Dans la semaine",
              "Dans les prochaines semaines",
              "Dans les prochains mois",
            ]}
            value={datas?.dateCreation}
            onChange={(v) => updateState("dateCreation", v)}
          />
        );

      case 3:
        return (
          <>
            <ARadioGroup
              name="datefirstTimeCreationCreation"
              choices={["Oui", "Non"]}
              value={datas?.firstTimeCreation}
              onChange={(v) => updateState("firstTimeCreation", v)}
            />

            {datas?.firstTimeCreation == "Non" && (
              <ARadioGroup
                name="previousCreation"
                choices={[
                  "J’ai déjà créé une auto entreprise",
                  "J'ai déjà créé une société commerciale",
                ]}
                value={datas?.previousCreation}
                onChange={(v) => updateState("previousCreation", v)}
              />
            )}
          </>
        );
      case 4:
        return (
          <ARadioGroup
            name="userStatus"
            choices={[
              "Salarié",
              "Demandeur d’emploi",
              "Entrepreneur",
              "Étudiant",
            ]}
            value={datas?.userStatus}
            onChange={(v) => updateState("userStatus", v)}
          />
        );
      case 5:
        return (
          <TextField
            value={datas?.nomSociete}
            onChange={(e) => updateState("nomSociete", e.target.value)}
            fullWidth
            label={fieldLabels["nomSociete"]}
            name="nomSociete"
          />
        );
      case 6:
        return (
          <ARadioGroup
            name="copyright"
            choices={[
              "Je veux protéger ma marque",
              "Je ne souhaite pas protéger ma marque",
            ]}
            value={datas?.copyright}
            onChange={(v) => updateState("copyright", v)}
          />
        );
      case 7:
        return (
          <>
            <ARadioGroup
              name="activity"
              choices={[
                "Bâtiment / aménagement",
                "Conseil / consulting / freelance",
                "E-Commerce",
                "Autres",
              ]}
              value={datas?.activity}
              onChange={(v) => updateState("activity", v)}
            />

            {datas?.activity == "Autres" && (
              <Autocomplete
                disablePortal
                id="otherActivity"
                options={formConstants.ACTIVITY_LIST}
                value={datas?.otherActivity}
                fullWidth
                onChange={(event, value) => updateState("otherActivity", value)}
                renderInput={(params) => (
                  <TextField {...params} label="Autre activité" />
                )}
                ListboxProps={{
                  style: {
                    maxHeight: "150px",
                  },
                }}
              />
            )}
          </>
        );
      case 8:
        return (
          <>
            <ARadioGroup
              name="activityChambreDesMetiers"
              choices={["Oui", "Non"]}
              value={datas?.activityChambreDesMetiers}
              onChange={(v) => {
                if (v == "Non") {
                  updateState("activityChambreDesMetiersGere", null);
                }
                updateState("activityChambreDesMetiers", v);
              }}
            />
            {datas?.activityChambreDesMetiers == "Oui" && (
              <>
                <Typography variant="h5" sx={{ fontWeight: 500 }}>
                  Pour les activités artisanales, l'enregistrement auprès de la
                  chambre des métiers et de l'artisanat est obligatoire. Cet
                  enregistrement est géré et coûte 79€ HT.
                </Typography>

                <ARadioGroup
                  name="activityChambreDesMetiersGere"
                  choices={["Oui", "Non", "Plus tard"]}
                  value={datas?.activityChambreDesMetiersGere}
                  onChange={(v) =>
                    updateState("activityChambreDesMetiersGere", v)
                  }
                />
              </>
            )}
          </>
        );
      case 9:
        return (
          <>
            <Typography>
              Il s’agit de l’adresse à laquelle toutes les correspondances
              officielles vous sont adressées pour la société.
            </Typography>
            <AddressSearch
              defaultValue={datas?.address}
              onSelectionChange={(address) =>
                updateState("address", address.properties.label)
              }
            />
          </>
        );
      case 10:
        return (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "0px",
            }}
          >
            {Object.entries(datas).map(([key, value]) => {
              return (
                <Flexbetween
                  sx={{ gap: "5px", cursor: "pointer" }}
                  onClick={() => dispatch(setPage({ page: keyToPage[key] }))} // Naviguer vers la page correspondante
                >
                  <Typography>{fieldLabels[key]}:</Typography>
                  <Typography variant="h6">{value}</Typography>
                </Flexbetween>
              );
            })}
          </Box>
        );
      case 11:
        return <StripeProducts />;
    }
  };

  const keyToPage = {
    lastName: 1,
    firstName: 1,
    email: 1,
    phone: 1,
    dateCreation: 2,
    firstTimeCreation: 3,
    previousCreation: 3,
    userStatus: 4,
    nomSociete: 5,
    copyright: 6,
    activity: 7,
    otherActivity: 7,
    activityChambreDesMetiers: 8,
    activityChambreDesMetiersGere: 8,
    address: 9,
  };

  const formulaireRender = () => {
    return (
      <Fragment>
        <Flexbetween
          sx={{
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "10px",
          }}
        >
          <Typography
            variant={largeScreen ? "h3" : "h4"}
            sx={{ fontWeight: 600 }}
          >
            {questionRender(page)}
          </Typography>
          {page < 11 && (
            <Typography variant="h5">
              Ces informations nous permettront de vous assister au cours de
              votre processus de création, et seront necessaires pour constituer
              votre dossier.
            </Typography>
          )}
        </Flexbetween>
        {formRender()}
        <Flexbetween gap={2}>
          {page > 1 && (
            <Button
              onClick={() => {
                if (page > 1) {
                  dispatch(setPage({ page: page - 1 }));
                }
              }}
              variant="contained"
            >
              retour
            </Button>
          )}
          {page < 11 && (
            <Button
              sx={{ pl: "24px", pr: "24px" }}
              onClick={() => {
                if (page < 11) {
                  dispatch(setPage({ page: page + 1 }));
                }
              }}
              disabled={!isStepEnable(page)}
              variant="contained"
              endIcon={<ArrowRightAltIcon />}
            >
              Continuer
            </Button>
          )}
        </Flexbetween>
        <InfoDialog
          title="Information sur vos données"
          message="Vos données sont protegées par la loi : LKmedia, éditeur du site Alcée
          avocats, collecte des données personelles à l'aide du present
          questionnaire afin d'assurer les services proposés sur le site. Vous
          disposez d'un droit d'accès, de rectification, de suppression et
          d'opposition au traitement des données à caractère personel vous
          concernant. Vous pouvez exercer ces droits à l'adresse email suivante:
          privacy@alcéeavocats.fr. Pour plus dinformations, veuillez consulter
          notre charte de respect de la vie privée"
        >
          <Flexbetween gap="10px">
            <InfoOutlined />
            Information sur vos données
          </Flexbetween>
        </InfoDialog>
      </Fragment>
    );
  };

  const videoCard = () => {
    if (largeScreen) {
      return (
        <Box
          sx={{
            borderRadius: "16px",
            aspectRatio: "0.8",
            height: "80%",
            maxHeight: "400px",
            overflow: "hidden",
            position: "sticky",
            top: "220px",
          }}
        >
          <video
            style={{ objectFit: "cover", objectPosition: "center" }}
            width="100%"
            height="100%"
            controls
            src={
              "https://www.alceeavocats.com/wp-content/uploads/2023/05/Alcee-Header.mp4"
            }
          ></video>
        </Box>
      );
    } else {
      return (
        <Box sx={{ position: "fixed", bottom: 10, right: 5, opacity: 0.6 }}>
          <EmptyDialog
            content={
              <Box
                sx={{
                  borderRadius: "16px",
                  aspectRatio: "0.8",
                  height: "80%",
                  maxHeight: "400px",
                  overflow: "hidden",
                }}
              >
                <video
                  style={{ objectFit: "cover", objectPosition: "center" }}
                  width="100%"
                  height="100%"
                  controls
                  src={
                    "https://www.alceeavocats.com/wp-content/uploads/2023/05/Alcee-Header.mp4"
                  }
                ></video>
              </Box>
            }
          >
            <Zoom
              key="inherit"
              in={true}
              timeout={100}
              style={{
                transitionDelay: "100ms",
              }}
              unmountOnExit
            >
              <Fab
                sx={{
                  color: "common.white",
                  bgcolor: theme.palette.primary.main,
                  "&:hover": {
                    bgcolor: theme.palette.primary.dark,
                  },
                }}
                aria-label="Expand"
                color="inherit"
              >
                Video
              </Fab>
            </Zoom>
          </EmptyDialog>
        </Box>
      );
    }
  };

  return (
    <Box sx={{ height: "100%", overflowY: "scroll" }}>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "#FFF",
          zIndex: 999,
        }}
      >
        <Navbar />
        <NavigationBar isStepEnable={(index) => isStepEnable(index)} />
      </Box>
      <Flexbetween
        sx={{
          justifyContent: "center",
          gap: "4rem",
          alignItems: "flex-start",
          minHeight: "calc(100% - 120px)",
        }}
      >
        {videoCard()}
        <Box
          sx={{
            width: largeScreen ? (page == 11 ? "50%" : "40%") : null,
            padding: "20px",
            mt: "50px",
          }}
        >
          <Flexbetween
            sx={{ flexDirection: "column", alignItems: "flex-start", gap: 4 }}
          >
            {formulaireRender()}
          </Flexbetween>
        </Box>
      </Flexbetween>
    </Box>
  );
};

export default Homepage;
