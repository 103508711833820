import { useEffect, useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import apiService from "services/apiService";
import { useSelector } from "react-redux";
import StripeCard from "./StripeCard";

const StripeProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const formData = useSelector((state) => state.datas); // Données utilisateur

  useEffect(() => {
    const fetchStripeProducts = async () => {
      try {
        setLoading(true);
        const { data } = await apiService.fetchData("/stripe/products");
        setProducts(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchStripeProducts();
  }, []);

  const handleCheckout = async (priceId) => {
    try {
      const { data } = await apiService.postData(
        "/stripe/create-checkout-session",
        { formData, price_id: priceId }
      );

      if (data.url) {
        window.location.href = data.url;
      }
    } catch (error) {
      console.error(
        "Erreur lors de la création de la session Checkout:",
        error
      );
    }
  };

  if (loading) return <div>Chargement des produits...</div>;
  if (error || !products)
    return <div>Erreur lors du chargement des produits</div>;

  return (
    <Grid container spacing={2}>
      {products.map((product) => {
        return <StripeCard result={product} />;
      })}
    </Grid>
  );
};

export default StripeProducts;
