import { Typography, useTheme, useMediaQuery, Button } from "@mui/material";
import { Call } from "@mui/icons-material";

import FlexBetween from "components/FlexBetween";
import logo from "../../assets/logo_alcee.svg";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "state";

const Navbar = () => {
  const isNonMobileScreens = useMediaQuery("(min-width: 800px)");
  const theme = useTheme();
  const alt = theme.palette.background.alt;
  const dispatch = useDispatch();
  const ref = useSelector((state) => state.ref);

  return (
    <FlexBetween padding="1rem 6%" backgroundColor={alt}>
      <img
        src={logo}
        height="45px"
        alt="logo Alcée"
        style={{ cursor: "pointer" }}
        onClick={() => {
          dispatch(setPage({ page: 1 }));
        }}
      />
      {isNonMobileScreens && (
        <FlexBetween gap="2rem">
          <Typography sx={{ fontWeight: 700 }}>Création de SASU</Typography>
          <Typography>Ref: {ref}</Typography>
          <Button
            variant="contained"
            startIcon={<Call sx={{ fontSize: "25px" }} />}
          >
            061256348967
          </Button>
        </FlexBetween>
      )}
      {!isNonMobileScreens && (
        <FlexBetween gap="1rem">
          <Typography sx={{ fontWeight: 700, fontSize: "0.5rem" }}>
            Création de SASU
          </Typography>
          <Typography>Ref: {ref}</Typography>
        </FlexBetween>
      )}
    </FlexBetween>
  );
};

export default Navbar;
